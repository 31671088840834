import React, {useEffect, useState} from 'react';
import {BrowserRouter, redirect, Route, Routes} from 'react-router-dom';
import NotFoundPage from "./pages/NotFoundPage";
import AppPage from "./pages/AppPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {I18nextProvider} from "react-i18next";
import i18n from "./translations/i18n";
import {changeLanguage} from "i18next";
import NotInRegion from "./pages/NotInRegion";
import {REGION_LOCK_ISO} from "./utils/options";

function App() {
    useEffect(() => {
        const userLanguage = navigator.language || navigator.languages[0];
        console.log("Setting language to " + userLanguage);

        i18n.changeLanguage(userLanguage).then(res => {
            console.log("Set language successfully");

            if(userLanguage == "ar") {
                document.dir = "rtl";
            }
        });
    }, []);

  return (
      <I18nextProvider i18n={i18n}>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<AppPage/>} />
                  <Route path="locked" element={<NotInRegion/>}/>
                  <Route path="*" element={<NotFoundPage/>} />
              </Routes>
              <ToastContainer />
          </BrowserRouter>
      </I18nextProvider>
  )
}

export default App;
