import {PageType} from "../../utils/PageType";
import React, {useEffect, useRef} from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {auth} from "../../utils/firebase";
import {CampaignService} from "../../services/CampaignService";
import {COUNTRY_EXTENSION} from "../../utils/options";

interface PhoneComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const PhoneComponent : React.FC<PhoneComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const { t } = useTranslation();

    const recaptchaRef = useRef<RecaptchaVerifier | undefined>(undefined);

    const onSignInSubmit = async () => {
        toast.dismiss();

        try {
            let inputNumber: string = userInfo.phone!.trim().replace(/\D/g, '').substring(0, 10);

            const isPhoneRedeemed = await CampaignService.isPhoneRedeemed(COUNTRY_EXTENSION + inputNumber);

            if(isPhoneRedeemed) {
                toast.error(t("alreadyRedeemedPhone"));
                return;
            }

            signInWithPhoneNumber(auth, COUNTRY_EXTENSION + inputNumber, recaptchaRef.current!)
                .then((confirmationResult) => {
                    setUserInfo({
                        ...userInfo,
                        phone: COUNTRY_EXTENSION + inputNumber,
                        confirmationResult: confirmationResult
                    })

                    setPageType(PageType.PHONE_VERIFICATION);
                }).catch((error) => {
                toast.error(t("waitForConfirmation"))
            });
        } catch (e) {
            toast.error((e as Error).message);
        }
    }

    const verifyRecaptcha = () => {
        recaptchaRef.current = new RecaptchaVerifier(auth, 'recaptcha-container-id', {
            'size': 'invisible'
        });

        recaptchaRef.current!.render().then(() => console.log("Rendered reCAPTCHA"))
    }

    const formatNumberAndSave = (phone: string) => {
        let inputNumber: string = phone.trim().replace(COUNTRY_EXTENSION!, "").replace(/\D/g, '').substring(0, 10); // Remove non-numeric characters and limit to 10 digits

        if (inputNumber.length > 3) {
            inputNumber = inputNumber.substring(0, 3) + '-' + inputNumber.substring(3);
        }
        if (inputNumber.length > 7) {
            inputNumber = inputNumber.substring(0, 7) + '-' + inputNumber.substring(7);
        }

        console.log(inputNumber);

        setUserInfo({
            ...userInfo,
            phone: inputNumber
        })
    };

    useEffect(() => {
        if(userInfo.phone.length > 0) {
            formatNumberAndSave(userInfo.phone)
        }

        verifyRecaptcha()
    }, []);

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.WELCOME)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('enterPhoneHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterPhoneDescription')}</p>
                    <div className={"flex flex-row mt-[7.5%] text-2xl text-black font-gilroy-bold w-full"}>
                        <img alt="saudi-flag" className="w-8 rounded-full" src={"https://firebasestorage.googleapis.com/v0/b/simly-dedfe.appspot.com/o/countries%2FSAU.png?alt=media&token=59cdd766-c6d6-4787-a22a-55a62d571c2"}/>
                        <div className={"flex items-center justify-center ml-[1.5%] w-20 h-8 bg-white"}>
                            <input
                                className={"text-center w-full text-2xl text-black font-gilroy-bold outline-none bg-white"}
                                disabled={true}
                                value={COUNTRY_EXTENSION}
                            />
                        </div>
                        <input
                            className={"ml-[3%] text-2xl text-black font-gilroy-bold outline-none placeholder-[#BFBFB9] w-full"}
                            onChange={(e) => formatNumberAndSave(e.target.value!)}
                            value={userInfo.phone}
                            placeholder={t('enterPhone')}
                            type={"tel"}
                            autoFocus
                        />
                    </div>
                </div>
                <div className={"mt-[15%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#E85929] select-none"} onClick={() => onSignInSubmit()}>
                        <p className="font-gilroy-semibold text-xl text-white">
                            {t('nextButton')}
                        </p>
                    </div>
                </div>
                <div id="recaptcha-container-id"></div>
            </div>
        </div>
    )
}

export default PhoneComponent;
